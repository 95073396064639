import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMeta from "vue-meta"
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import vuePositionSticky from 'vue-position-sticky'
import VueMasonry from 'vue-masonry-css'
import i18n from './plugins/i18n'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'
import VueGtag from "vue-gtag"
import VueCookies from 'vue-cookies'

Vue.config.productionTip = true

Vue.use(VueMasonry);
Vue.use(VueTelInputVuetify, {
    vuetify,
});
Vue.use(VueMeta)
Vue.use(VuetifyDialog, {
    context: {
        vuetify
    }
})
Vue.use(vuePositionSticky)
Vue.use(VueGtag, {
    config: {id: "G-M50KD7649T"}
})
Vue.use(VueCookies)

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app')
