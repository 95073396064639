import axios from 'axios';
import store from '@/store';
import router from '@/router';
import parameters from '@/parameters.json'

const apiURL = parameters.env === 'prod' ? parameters.apiBaseUrl.prod : parameters.apiBaseUrl.dev
const HTTP = axios.create({baseURL: apiURL});

HTTP.interceptors.request.use(
    async (config) => {
        config.headers = (function () {
            let authState = JSON.parse(localStorage.getItem('authData') || '{}');
            if (authState && authState.loggedIn === true && authState.token) {
                return {
                    Authorization: 'Bearer ' + authState.token,
                };
            } else {
                return {};
            }
        })();
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

HTTP.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const path = window.location.pathname;
        if (error.response.status === 401 && path !== '/') {
            store.commit('auth/logout');
            await router.push({name: 'home'});
        } else {
            return Promise.reject(error);
        }
    }
);

export default {
    auth: {

        /**
         * Do login to the API.
         * @param loginForm
         * @returns {*}
         */
        login: async (credentials) => {
            return HTTP.post(apiURL + 'login_check', {
                username: credentials.phone,
                password: credentials.password,
            });
        },

        /**
         * Register a new user.
         * @param form
         * @returns {Promise<AxiosResponse<any>>}
         */
        register: async (form) => {
            return HTTP.post(apiURL + 'v1/users/register', {
                full_name: form.fullName,
                phone_number: form.phone,
                password: form.password,
            });
        },
    },
    business: {

        /**
         * Get the full info of the business.
         * @param businessSlug
         * @returns {Promise<AxiosResponse<any>>}
         */
        getFullInfo: async (businessSlug) => {
            return HTTP.get(apiURL + 'v1/extra/full-business/' + businessSlug);
        },

        /**
         * Get the info of the business.
         * @param businessSlug
         * @returns {Promise<AxiosResponse<any>>}
         */
        getBusinessInfo: async (businessSlug) => {
            return HTTP.get(apiURL + 'v1/business/' + businessSlug);
        },

        /**
         * Get the business of the logged user.
         * @returns {Promise<AxiosResponse<any>>}
         */
        getMyBusiness: async () => {
            return HTTP.post(apiURL + 'v1/business/filter', {
                order_by: {
                    field: "_created",
                    order: "ASC"
                }
            });
        },

        /**
         * Create a new business.
         * @param business
         * @returns {Promise<AxiosResponse<any>>}
         */
        createBusiness: async (business) => {
            return HTTP.post(apiURL + 'v1/business', business);
        },

        /**
         * Update the business info.
         * @param business
         * @returns {Promise<AxiosResponse<any>>}
         */
        updateBusiness: async (business) => {
            return HTTP.put(apiURL + 'v1/business/' + business.id, business);
        },

        /**
         *
         * @param businessId
         * @returns {Promise<AxiosResponse<any>>}
         */
        getBusinessProducts: async (businessId) => {
            return HTTP.post(apiURL + 'v1/business/' + businessId + '/products/filter');
        },

        /**
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getPromotedBusiness: async (options) => {
            return HTTP.get(apiURL + 'rsc/ft/business-ft', {params: options});
        },

        /**
         *
         * @param options
         * @returns {Promise<AxiosResponse<any>>}
         */
        getAllBusiness: async (options) => {
            return HTTP.get(apiURL + 'rsc/ft/business-ft', {params: options});
        },

        /**
         *
         * @returns {Promise<AxiosResponse<any>>}
         */
        getBusinessFilterData: async () => {
            return HTTP.get(apiURL + 'rsc/ft/filter-value');
        },

        getManifestUrl: (slug) => {
            return apiURL + 'v1/extra/gm/' + slug + '/manifest.json';
        }
    },
    products: {

        /**
         *
         * @param businessSlug
         * @returns {Promise<AxiosResponse<any>>}
         */
        getProducts: async (businessSlug) => {
            return HTTP.post(apiURL + 'v1/business/' + businessSlug + '/products/filter', {
                order_by: {
                    field: "name",
                    order: "ASC"
                },
                pagination: {
                    page: 1,
                    count: 1000
                }
            });
        },

        /**
         *
         * @param businessSlug
         * @param product
         * @returns {Promise<AxiosResponse<any>>}
         */
        setProductStatus: async (businessSlug, product) => {
            return HTTP.put(apiURL + 'v1/business/' + businessSlug + '/products/' + product.id + '/set-status', {
                status: product.is_active
            });
        },

        /**
         *
         * @param businessSlug
         * @param product
         * @returns {Promise<AxiosResponse<any>>}
         */
        deleteProduct: async (businessSlug, product) => {
            return HTTP.delete(apiURL + 'v1/business/' + businessSlug + '/products/' + product.id);
        },

        /**
         *
         * @param businessSlug
         * @param productId
         * @returns {Promise<AxiosResponse<any>>}
         */
        getProduct: async (businessSlug, productId) => {
            return HTTP.get(apiURL + 'v1/business/' + businessSlug + '/products/' + productId);
        },

        /**
         *
         * @param businessSlug
         * @param product
         * @returns {Promise<AxiosResponse<any>>}
         */
        createProduct: async (businessSlug, product) => {
            return HTTP.post(apiURL + 'v1/business/' + businessSlug + '/products', product);
        },

        /**
         *
         * @param businessSlug
         * @param product
         * @returns {Promise<AxiosResponse<any>>}
         */
        updateProduct: async (businessSlug, product) => {
            return HTTP.put(apiURL + 'v1/business/' + businessSlug + '/products/' + product.id, product);
        }
    },
    categories: {
        /**
         *
         * @param businessSlug
         * @returns {Promise<AxiosResponse<any>>}
         */
        getBusinessCategories: async (businessSlug) => {
            return HTTP.post(apiURL + 'v1/business/' + businessSlug + '/category/filter');
        },

        /**
         *
         * @param businessSlug
         * @param category
         * @returns {Promise<AxiosResponse<any>>}
         */
        createBusinessCategory: async (businessSlug, category) => {
            return HTTP.post(apiURL + 'v1/business/' + businessSlug + '/category', category);
        },

        /**
         *
         * @param businessSlug
         * @param category
         * @returns {Promise<AxiosResponse<any>>}
         */
        deleteBusinessCategory: async (businessSlug, category) => {
            return HTTP.delete(apiURL + 'v1/business/' + businessSlug + '/category/' + category.id);
        },

        /**
         * Sort categories.
         * @param businessSlug
         * @param categories
         * @returns {Promise<AxiosResponse<any>>}
         */
        orderCategories: async (businessSlug, categories) => {
            return HTTP.post(apiURL + 'v1/business/' + businessSlug + '/category/order', categories);
        }
    },
    orders: {

        /**
         * Create a new one order with the data of the order.
         * @param businessId
         * @param deliveryType
         * @param order
         * @param client
         * @returns {Promise<AxiosResponse<any>>}
         */
        createOrder: async (businessId, order, client) => {
            return HTTP.post(apiURL + 'v1/extra/business/' + businessId + '/order', {
                order_number: order.orderNumber,
                products: order.products,
                home_delivery: order.home_delivery,
                client: {
                    name: client.name,
                    address: client.address,
                    phone: client.phone,
                    note: client.note
                },
            });
        },

        /**
         * Get a Order.
         * @param orderId
         * @returns {Promise<AxiosResponse<any>>}
         */
        getOrder: async (businessSlug, orderId) => {
            return HTTP.get(apiURL + 'v1/extra/business/' + businessSlug + '/order/' + orderId)
        },
    },
    users: {

        /**
         * Update the information of the user.
         * @param userInfo
         * @returns {Promise<AxiosResponse<any>>}
         */
        updateUserInfo: async (userInfo) => {
            return HTTP.put(apiURL + 'v1/users/' + userInfo.id, {
                full_name: userInfo.fullName,
                old_password: userInfo.oldPassword,
                password: userInfo.password
            });
        }
    },
    media: {

        /**
         * Upload an image to the business.
         * @param file
         * @returns {Promise<AxiosResponse<any>>}
         */
        uploadBusinessImage: async (businessId, file) => {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('business', businessId);
            formData.append('type', 'logo');
            return HTTP.post('/v1/media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        /**
         *
         * @param businessId
         * @param file
         * @returns {Promise<AxiosResponse<any>>}
         */
        uploadBusinessCoverImage: async (businessId, file) => {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('business', businessId);
            formData.append('type', 'cover');
            return HTTP.post('/v1/media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        /**
         * Upload few images to the products.
         * @param productId
         * @param files
         * @returns {Promise<AxiosResponse<any>>}
         */
        uploadProductsImages: async (productId, files) => {
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('file' + (i + 1), files[i]);
            }
            formData.append('product', productId);
            return HTTP.post('/v1/media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
    },
    statistic: {
        reportBusinessVisit: async (businessSlug) => {
            return HTTP.post(apiURL + 'rsc/st/business-pv', {
                business: businessSlug,
            });
        },
        reportProductVisit: async (productId) => {
            return HTTP.post(apiURL + 'rsc/st/product-pv', {
                product: productId,
            });
        }
    }
};
