import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "home-page" */ '../views/Home.vue'),
        },
        {
            path: '/business',
            name: 'business',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "business" */ '../views/Business'),
        },
        {
            path: '/admin',
            name: 'admin',
            redirect: '/admin/welcome',
            meta: {requiresAuth: true},
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/Index.vue'),
            children: [
                {
                    path: '/admin/profile',
                    name: 'profile',
                    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/Profile'),
                },
                {
                    path: '/admin/subscription',
                    name: 'Subscription',
                    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/Subscription'),
                },
                {
                    path: '/admin/welcome',
                    name: 'admin_welcome',
                    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/Welcome'),
                },
                {
                    path: 'admin/business',
                    name: 'admin_business',
                    props: true,
                    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/business/Index'),
                    children: [
                        {
                            path: '/admin/business/:slug?',
                            name: 'admin_business_edit_business',
                            props: true,
                            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/business/EditBusiness'),
                        },
                        {
                            path: '/admin/business/:slug/products',
                            name: 'admin_business_list_products',
                            props: true,
                            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/business/ListProducts'),
                        },
                        {
                            path: '/admin/business/:slug/edit-products/:productId?',
                            name: 'admin_business_edit_products',
                            props: true,
                            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "admin-page" */'../views/admin/business/EditProduct'),
                        },
                    ]
                },
            ],
        },
        {
            path: '/error',
            name: 'error',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "error-page" */'../views/Error.vue'),
        },
        {
            path: '/error-internal',
            name: 'errorInternal',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "error-page" */'../views/ErrorInternal.vue'),
        },
        {
            path: '/terms',
            name: 'terms',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "policy-terms-page" */'../views/Terms.vue'),
        },
        {
            path: '/policy',
            name: 'policy',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "policy-terms-page" */'../views/Policy.vue'),
        },
        {
            path: '/:slug/invoice/:invoice',
            name: 'invoice',
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "business-page" */'../views/Invoice'),
        },
        {
            path: '/:slug',
            name: 'businessDetails',
            meta: {denyBackButton: true},
            component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "business-page" */'../views/BusinessDetails.vue'),
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    }

});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((record) => record.meta.requiresAuth);
    try {
        const authData = JSON.parse(localStorage.getItem('authData') || '{}');
        if (authRequired && (!authData || authData.loggedIn !== true)) {
            next('/');
        } else {
            next()
        }
    } catch (error) {
        next('/');
    }
});
export default router;
