import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00999a', // green
                secondary: '#de8d55', // orange
                accent: 'colors.indigo.base', // #3F51B5
                orangeDark: '#d3691f',
                primaryDark: '#008686',
                greenLight: '#ebf5f5',
                greyPrimary: '#f5f5f7',
                success: '#00999a',
                info: '#005F99',
                warning: '#DE8D55',
                error: '#DD5A54',
            },
        },
    },
});

//notificationBackground: '#1e2a36'
//notificationSuccess: '#00c9a1'
//notificationError: '#ff5457'
