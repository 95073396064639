import services from '../services';

const authData = JSON.parse(localStorage.getItem('authData') || '{}');
const initialState = authData
    ? {
        loggedIn: authData.loggedIn || false,
        token: authData.token || undefined,
        user: authData.user || undefined,
        business: authData.business || []
    }
    : {loggedIn: false, token: undefined, user: undefined, business: []};

export default {
    namespaced: true,
    state: initialState,
    mutations: {

        /**
         *
         * @param state
         * @param user
         */
        loginSuccess(state, authData) {
            state.loggedIn = true;
            state.token = authData.token;
            state.user = authData.user;
            state.business = authData.business || [];
            localStorage.setItem('authData', JSON.stringify(state));
        },

        /**
         *
         * @param state
         * @param user
         */
        updateUserData(state, user) {
            state.user = user;
            localStorage.setItem('authData', JSON.stringify(state));
        },

        /**
         *
         * @param state
         * @param business
         */
        updateBusiness(state, business) {
            let exist = false;
            for (let i = 0; i < state.business.length; i++) {
                if (state.business[i].id === business.id) {
                    state.business[i] = business
                    exist = true;

                }
            }

            if (exist === false)
                state.business.push(business);

            localStorage.setItem('authData', JSON.stringify(state));
        },

        /**
         *
         * @param state
         */
        loginFailure(state) {
            state.loggedIn = false;
            state.token = undefined;
            state.user = undefined;
            state.business = [];
        },
        /**
         *
         * @param state
         */
        logout(state) {
            state.loggedIn = false;
            state.token = undefined;
            state.user = undefined;
            state.business = [];
            localStorage.removeItem('authData');
        },
    },
    actions: {

        /**
         *
         * @param commit
         * @param credentials
         * @returns {*}
         */
        login({commit}, credentials) {
            return services.auth.login(credentials).then(
                (result) => {
                    commit('loginSuccess', result.data);
                    return Promise.resolve(result.data);
                },
                (error) => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },

        /**
         *
         * @param commit
         * @param user
         */
        updateUser({commit}, user) {
            commit('updateUserData', user);
        },

        updateBusiness({commit}, business) {
            commit('updateBusiness', business);
        },

        /**
         *
         * @param commit
         */
        logout({commit}) {
            commit('logout');
        },
    },
};
