import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        'hello': 'Hello World',
    },
    'es': {
        'hello': 'Hola Mundo',
    }
};

export default new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    messages,
});
